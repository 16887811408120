import { resultIds } from '../containers/SearchPage/SearchPage.duck';
import {
  fetchBlog,
  fetchBlogs,
  fetchSeoCategory,
  fetchSeoPages,
  fetchTagsFilter,
} from '../util/api';
import { isArrayLength } from '../util/genericHelpers';
import { createImageVariantConfig } from '../util/sdkLoader';
import { parse } from '../util/urlHelpers';
import { addMarketplaceEntities } from './marketplaceData.duck';

export const FETCH_CATEGORIESS_SUCCESS = 'app/strapi/FETCH_CATEGORIESS_SUCCESS';
export const SAVE_HOMEPAGE_SECTIONS = 'app/strapi/SAVE_HOMEPAGE_SECTIONS';
export const FETCH_SINGLE_CATEGORIESS_SUCCESS = 'app/strapi/FETCH_SINGLE_CATEGORIESS_SUCCESS';

export const SEARCH_BLOGS_REQUEST = 'app/strapi/SEARCH_BLOGS_REQUEST';
export const SEARCH_BLOGS_SUCCESS = 'app/strapi/SEARCH_BLOGS_SUCCESS';
export const SEARCH_BLOGS_ERROR = 'app/strapi/SEARCH_BLOGS_ERROR';
export const SEARCH_SINGLE_BLOGS_SUCCESS = 'app/strapi/SEARCH_SINGLE_BLOGS_SUCCESS';
export const SEARCH_LISTINGS_SUCCESS = 'app/strapi/SEARCH_LISTINGS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  seoCategories: [],
  homepageSections: [],
  seoCategory: null,
  searchInProgress: false,
  searchBlogsError: null,
  blogsData: [],
  currentPageResultIds: [],
  singleBlogData: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CATEGORIESS_SUCCESS:
      return {
        ...state,
        seoCategories: payload,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload?.data?.data?.map(l => l?.id)|| [],
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SAVE_HOMEPAGE_SECTIONS:
      return {
        ...state,
        homepageSections: payload,
      };
    case FETCH_SINGLE_CATEGORIESS_SUCCESS:
      return {
        ...state,
        seoCategory: payload,
      };
    case SEARCH_BLOGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        blogsData: payload?.data?.blogs || [],
        blogTags: payload?.data?.filters || [],
      };
    case SEARCH_SINGLE_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        singleBlogData: payload?.data || [],
      };
    case SEARCH_BLOGS_ERROR:
      return { ...state, searchInProgress: false, searchBlogsError: true };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const fetchSeoPagesSelector = state => {
  return state.strapi.seoCategories;
};

export const getBlogsInProgress = state => state.strapi?.searchInProgress;
export const getBlogsSelector = state => state.strapi?.blogsData;
export const getBlogsTagsSelector = state => state.strapi?.blogTags;

export const getBlogSelector = state => state.strapi.singleBlogData;
export const getSeoCategory = state => state.strapi.seoCategory;
export const getSeoListings = state => state.strapi.currentPageResultIds;

// ================ Action creators ================ //

export const fetchSingleSeoCategorySuccess = payload => ({
  type: FETCH_SINGLE_CATEGORIESS_SUCCESS,
  payload,
});

export const fetchSeoCategoriesSuccess = payload => ({
  type: FETCH_CATEGORIESS_SUCCESS,
  payload,
});

export const saveHomepageSections = payload => ({
  type: SAVE_HOMEPAGE_SECTIONS,
  payload,
});

export const searchBlogsRequest = () => ({
  type: SEARCH_BLOGS_REQUEST,
});

export const searchBlogsSuccess = response => ({
  type: SEARCH_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchSingleBlogsSuccess = response => ({
  type: SEARCH_SINGLE_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchBlogsError = () => ({
  type: SEARCH_BLOGS_ERROR,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

// ================ Thunks ================ //

export const fetchSeoCategoryBySlug = params => async (dispatch, getState, sdk) => {
  const { config, ...rest } = params;
  try {
    const { category } = (await fetchSeoCategory({ ...rest })) || {};
    if (isArrayLength(category) && category[0]) {
      const url = category[0]?.attributes?.url;
      const parsedURL = parse(url);
 
      const response = await sdk.listings.query({
        bounds: parsedURL?.bounds,
        pub_isInfoOnly: false, // Query all bookable listings
        include: ['author', 'images'],
        'fields.listing': [
          'title',
          'geolocation',
          'price',
          'publicData.listingType',
          'publicData.transactionProcessAlias',
          'publicData.unitType',
          // These help rendering of 'purchase' listings,
          // when transitioning from search page to listing page
          'publicData.pickupEnabled',
          'publicData.shippingEnabled',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [
          'variants.scaled-small',
          'variants.scaled-medium',
          `variants.listing-card`,
          `variants.listing-card-2x`,
        ],
        ...createImageVariantConfig(`listing-card`, 400, 1),
        ...createImageVariantConfig(`listing-card-2x`, 800, 1),
        'limit.images': 1,
      });
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      dispatch(fetchSingleSeoCategorySuccess(category[0]));
    }
  } catch (error) {
    console.log(error, 'Error while fetching seo category');
  }
};

export const fetchSeoCategories = () => async (dispatch, getState, sdk) => {
  try {
    const { categories = {} } = (await fetchSeoPages({})) || {};
    const filteredCategories = isArrayLength(categories)
      ? categories.filter((v, i, a) => a?.findIndex(v2 => v2?.id === v?.id) === i)
      : [];
    dispatch(fetchSeoCategoriesSuccess(filteredCategories));
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchSingleBlog = params => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());
  try {
    const response = await fetchBlog({ ...params });
    dispatch(searchSingleBlogsSuccess(response?.blog));
    return response;
  } catch (error) {
    dispatch(searchBlogsError());
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchAllBlogs = params => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());

  try {
    let blogsResponse, tagsResponse;

    // Fetch blogs
    blogsResponse = await fetchBlogs({ ...params });
    tagsResponse = await fetchTagsFilter({});

    dispatch(
      searchBlogsSuccess({
        blogs: blogsResponse?.blogs,
        filters: tagsResponse ? tagsResponse?.tags : [],
      })
    );
    return blogsResponse?.blogs;
  } catch (error) {
    dispatch(searchBlogsError());
  }
};
